import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_5reasons.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="5 Reasons to Try AI-Based IT Support for Your Business"
        description="how AI is transforming IT support and Why You Should Try AI-Based IT Support for Your Business"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            5 Reasons Why You Should Try AI-Based IT Support for
                            Your Business
                          </h1>
                        </div>
                      </div>
                      <img
                        src={BlogHeader}
                        alt="  5 Reasons Why You Should Try AI-Based IT Support for
                            Your Business"
                      />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Artificial Intelligence refers to the simulation of
                          human intelligence processes by machines, computer
                          systems to be precise. These processes primarily
                          include learning, reasoning, and self-correction.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Machine learning is a term that is synonymous with AI.
                          As the name suggests, machine learning refers to
                          empowering machines to learn by themselves using the
                          data provided and predict the best possible outcome of
                          a complex problem. Recent studies have revealed;
                          artificial intelligence and machine learning are
                          rapidly revolutionizing IT support.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In this blog post, we shall discuss how AI is
                          transforming IT support and why you must try it.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform">
                            {" "}
                            Role of AI in IT Support{" "}
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To understand how AI and ML are expected to{" "}
                          <a href="https://workativ.com/conversational-ai-platform/impact-of-AI">
                            revolutionize IT support,
                          </a>{" "}
                          you must understand its functions. So, let’s discuss
                          them in detail.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          Well-Informed Actions
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          The power of AI to multi-task and quickly respond to
                          queries of the customers is enabling it to
                          revolutionize the customer service space rapidly.
                          Industry analysts are of the view that AI-assisted
                          customer service platforms generate quick responses,
                          with a high accuracy level, which humans fail to
                          deliver. They further add that AI for customer
                          services makes self-service interfaces more intuitive
                          and economical, helping business owners to anticipate
                          better specific customer needs through behavior
                          analysis, buying trends, browsing patterns, chat
                          history, and preferences.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Multi-Channels of Support
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          AI not only plays a critical role in providing direct
                          assistance to customers but also guides them to make
                          the right decision as per their preference. If the
                          customers encounter any issue during their buyer’s
                          journey, an AI-based intelligent support system will
                          guide them towards parallel support channels. This
                          way, customers will not be deprived of efficient
                          solutions and service commitments.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Uninterrupted Service
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          In this fast-paced world, constant and fast customer
                          support is essential for every business. A surprising
                          benefit of using{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/it-support-automation-guide">
                            AI for IT support
                          </a>{" "}
                          is that it automates responses. Thus, companies will
                          remain connected to their customers, even if the
                          customer support team is on holiday or week offs. Most
                          importantly, the customers will be able to interact
                          with the AI robot at any point in time to solve their
                          issues. In simple words, AI-based IT support solutions
                          help businesses stay responsive to their customers
                          24/7.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Complete Reliability
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A recent study conducted by eminent market research
                          firms revealed the fact that AI-based IT support tools
                          provide a certain level of reliability, which is
                          almost impossible for humans to match. Service-based
                          industries power their everyday interactions with
                          their customers using the cognitive knowledge base of
                          intelligent chatbots. The study further added that
                          these tools being free from afflictions and biases
                          always deliver optimal solutions to the customers. The
                          flexibility offered also improves customer service
                          interactions and impacts the decisions of the
                          existing, as well as new customers.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          <a href="https://workativ.com/conversational-ai-platform/cost-reduction">
                            {" "}
                            Cost-Effective Solution
                          </a>
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Businesses these days are always seeking new
                          opportunities to streamline business operations and
                          reduce expenditure. Automating support and business
                          operations via AI-enabled platforms helps businesses
                          minimize effort, time, and cost as a whole. The
                          pre-programmed intelligent system stuffed with
                          domain-specific knowledge base helps mitigate
                          expenditure by considerably automating the day-to-day
                          operations.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Companies across the globe are now embracing this boon
                          of advanced science to not only build a productive
                          workforce but also to expand the usability of their
                          products. Chatbots, virtual assistants, intelligent
                          routing, user sentiment analysis, predictive
                          analytics, contextual knowledge are continually
                          diversifying the IT support industry.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So, don’t you think it’s high time for you to make the
                          switch to AI-powered tools?
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Take the initiative by signing up to use the beta
                          version of Workativ Assistant for free.{" "}
                          <a href="https://workativ.com/?utm_source=blog&utm_medium=organic&utm_campaign=blog&utm_term=organic&utm_content=5%20Help%20Desk%20Tasks%20Everyone%20Should%20Automate">
                            {" "}
                            Register now{" "}
                          </a>
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/build-or-buy-virtual-agent">
                                Build or buy Virtual Agent
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                {" "}
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
